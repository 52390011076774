import React, { Component, createContext } from 'react';

export const UserContext = createContext();

class UserContextProvider extends Component {

    state = { 
        isAuthenticated: false,
        allUsers:[],
        url: 'http:/hola',
        userName: '',
        room:'',
        roomJoined:'',
        showPoints:false,
        observer: false
    }

    setUrl = (url) => {
        this.setState({ url: url});
    }
    
    setUsers = (users) => {
        this.setState({ allUsers: users });
    }
    
    setUsername = (username) => {
        this.setState({userName: username})
    }
    
    setRoom = (room) => {
        this.setState({room})
    }
    
    setRoomJoined = (roomJoined) => {
        this.setState({roomJoined})
    }
    
    showVotes = (status) => {
        this.setState({ showPoints: status});
    }
    
    setObserver = (observer) => {
        this.setState({ observer: observer});
    }

    render() { 
        return ( 
            <UserContext.Provider value={{ ...this.state, setUrl:this.setUrl, setUsers:this.setUsers, setUsername:this.setUsername, setRoom:this.setRoom, setRoomJoined:this.setRoomJoined, showVotes:this.showVotes, setObserver:this.setObserver }}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserContext;

export { UserContextProvider }