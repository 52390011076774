import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const Footer = () => {
    return(
        <div className="footer-wrapper">
            <div style={{display:"flex", justifyContent:"flex-end"}}>
                <div style={{display:"flex", alignItems:"center"}}>
                    <FavoriteBorderIcon style={{ fontSize: 18, marginRight:"2px" }} ></FavoriteBorderIcon>
                    <a 
                        target="__blank" 
                        href="https://forms.gle/bG25RNR86MD6JhUL8"
                        style={{fontSize:"13.5px",display:"flex", justifyContent:"flex-end", color:"#000"}} >
                            Feedback/Suggestions
                    </a>
                </div>
            </div>
        </div>

    )
}

export default Footer;