import { useState, useContext } from 'react';
import UserContext from './../context/UserContext';
import { Button, TextField, Grid, Container, Checkbox, FormControlLabel} from '@material-ui/core';
import {socket} from "./../service/socket";

const Login = (props) => {
    
    const [userError, setUserError] = useState(false);
    const [roomError, setRoomError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    

    const context = useContext(UserContext);
    
    
    const joinRoom = (e) => {
        if(context.userName == ''){
            setUserError(true);
        }
        if(context.room == '' || context.room.indexOf(' ') >= 0){
            setRoomError(true);
            if(context.room.indexOf(' ') >= 0){
                setErrorMessage("This field can't contain whitespaces")
            }
        }
        if(context.userName == '' || context.room == '' || context.room.indexOf(' ') >= 0){
            return false;
        }
        socket.emit('joinRoom', {username:context.userName, room: context.room, observer: context.observer});
        context.setRoomJoined(context.room);
        window.history.pushState({}, '', `/${context.room}`);
    }
    
    const handleUsername = (e) => {
        context.setUsername(e.target.value);
    }
    
    const handleRoom = (e) => {
        context.setRoom(e.target.value);
    }
    
    const handleObserver = (e) => {
        context.setObserver(e.target.checked);
    }
        
    return(
        <Container className="login-screen" maxWidth="false">
            <Container maxWidth="md">
                <Grid item xs={12} style={{padding:0}}>
                    <div style={{ display:"flex", justifyContent:"center", height:"100vh", flexDirection:"column", alignItems:"center"  }}>
                        <h2><span style={{marginRight:"5px"}}>♠️</span>Sprint Poker</h2>              
                        <div style={{ display:"flex", justifyContent:"center", alignItems:"flex-start"  }}>
                            
                        
                            <div style={{ padding:"0 15px", width:"50%"}}>
                                <h4 style={{ margin:"16px 0 0"}} >Scrum Poker Tool for Developers</h4>
                                <p>This is a free tool that helps your team to estimate the different requirements. It's a very
                                    simple tool, just create a room, share the link of the room with your collegues and start pointing the requirements 🙂
                                </p>
                            </div>
                            <div style={{ display:"flex", justifyContent:"flex-end", flexWrap:"wrap", paddingBottom: "50px"  }}>
                                <TextField fullWidth margin="normal" error={userError} required value={context.userName} onInput={handleUsername} id="username" label="Username" variant="outlined" inputProps={{ maxLength: 20 }}/>    
                                <TextField fullWidth margin="normal" error={roomError} helperText={errorMessage} required value={context.room} field="text" onInput={handleRoom} id="room" label="Room" variant="outlined" inputProps={{ maxLength: 20 }}/>    
                                <div style={{width:"100%"}}>
                                    <FormControlLabel control={<Checkbox onChange={handleObserver} color="primary" name="checkedC" />} label="Log In as Observer" />    
                                </div>
                                <Button margin="normal" variant="contained" color="primary" onClick={joinRoom}>
                                    Create Session
                                </Button>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Container>
        </Container>
    );
}

export default Login;