import React, { useState } from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography, Link, Tooltip }  from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';


const InviteLink = (props) => {
    const [open, setOpen] = useState(false);

    const room = props.room;
    
    const handleTooltipOpen = (e) => {
        e.preventDefault();
        setOpen(true);
        setTimeout( () => {
            setOpen(false);
        }, 1500);
        
    };
    
  return (
    <div>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Invite Teammates</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <CopyToClipboard text={`${window.location.href}`}>
                        <div>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied"
                            >
                                <Link helperText="Incorrect entry." onClick={handleTooltipOpen} href="">{window.location.href}</Link>
                            </Tooltip>
                            <div style={{color:"grey", fontSize:"0.75rem"}}>Click to copy the url.</div>
                        </div>

                    </CopyToClipboard>
                    


                </Typography>
            </AccordionDetails>
        </Accordion>
    </div>
  );
}

export default InviteLink;