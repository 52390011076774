import React, { useState, useEffect, Component } from "react";
import { UserContextProvider } from './context/UserContext';
import MainComponent from './components/MainComponent';
import Footer from './components/Footer';
import { AppBar, IconButton,  Toolbar, Typography, Container } from '@material-ui/core';
import './App.scss';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#ffac33',
            main: '#ff9800',
            dark: '#b26a00',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#FFF',
            dark: '#ff9800',
            contrastText: '#000',
        },
    },
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 13.5,
        body1: {
            fontSize:13.5
        },
        h6:{
            fontSize:"0.9rem"
        }
    },
});

function App() {
    return(
        <div className="App">
            <ThemeProvider theme={theme}>
                <UserContextProvider>
                    <MainComponent></MainComponent>
                </UserContextProvider>
                <Container>
                    <Footer/>
                </Container>
            </ThemeProvider>
        </div>
    )
}

export default App;
