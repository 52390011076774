import React, { Component } from 'react';
import UserContext from '../context/UserContext';
import { Button, TextField, Grid, Container, AppBar, Toolbar, IconButton, Typography, Card, CardContent} from '@material-ui/core';
import Messages from './Sidebar/Messages';
import Users from './Users';
import UsernameDialog from './UsernameDialog';
import Login from './Login';
import InviteLink from './Sidebar/InviteLink';
import Statistics from './Sidebar/Statistics';
import {socket} from "./../service/socket";

class MainComponent extends Component {
    
    static contextType = UserContext;
    
    constructor(props) {
        super(props);
        const context = this.context;
    
        this.state = {
            message: '',
            points: [
                { id: 1 }, 
                { id: 2 }, 
                { id: 3 }, 
                { id: 5 }, 
                { id: 8 }, 
            ],
            clicked: null
        }
    }
    
    componentDidMount(){
        const context = this.context;

        socket.on('roomUsers', ({users}) => {
            context.setUsers(users)
            
        });
        socket.on('showPoints', () => {
            this.context.showVotes(true);
        });
        
        socket.on('hidePoints', () => {
            this.context.showVotes(false);
            this.setState({clicked:null});
        });
        
        
        
        let path = window.location.pathname;
        path = path[0] == '/' ? path.substr(1) : path;
        if(path != ''){
            context.setRoomJoined(path);
        }
    }
    
    emitMessage(e){
        socket.emit('chat message', this.state.message);
        socket.emit('chatMessage', this.state.message);
        return false;
    }
    
    handleUsername(field, e){
        
        this.context.setUsername(e.target.value);
    }
    
    handleRoom(field, e){
        this.setState({"room":e.target.value});
    }
    
    handleMessage(field, e){
        this.setState({"message":e.target.value});
    }
    
    clearVotes(){
        socket.emit('clearVotes');
    }
    
    showVotes(){
        socket.emit('showVotes');
    }
    
    sendPoints(id, e) {
        this.setState({ clicked: id });
        socket.emit('emitPoints', e.target.value);
    }
    
    render(){
        const { userName } = this.context;
        const { points, clicked } = this.state;
        return(
            <div>
                    {
                        this.context.roomJoined
                        ?
                        <React.Fragment>
                            <AppBar position="static">
                                <Toolbar variant="dense" style={{display:"flex", justifyContent:"space-between"}}>
                                    <Typography style={{fontSize:"18px"}} color="inherit">
                                    ♠️ Sprint Poker
                                    </Typography>
                                    <div>{`Hello ${userName}!!`}</div>
                                </Toolbar>
                            </AppBar>
                                <Container className="main-container" maxWidth="md">
                                    <Grid container spacing={3}>
                                        <UsernameDialog roomJoined={this.context.roomJoined}></UsernameDialog>
                                            <Grid className="game-wrapper" item xs={12} sm={8} style={{display:"flex", flexDirection:"column"}}>
                                                <h3><span className="emoji">🎲</span>Game Information and Actions</h3>
                                                <div style={{display:"flex"}}>
                                                    <Card style={{width:"100%"}}>    
                                                        <CardContent>
                                                        <div>
                                                            <Typography style={{padding:"2px 0"}} variant="h6" color="inherit">
                                                            <span style={{fontWeight:"600"}}>Room:</span>{` ${this.context.roomJoined}`}
                                                            </Typography>
                                                            <Typography style={{padding:"2px 0"}} variant="h6" color="inherit">
                                                                <span style={{fontWeight:"600"}}>Total Users:</span>{` ${this.context.allUsers.length}`}
                                                            </Typography>
                                                        </div>

                                                        </CardContent>
                                                    </Card>
                                                    <div style={{width:"100%", padding:"15px 0px 0px 30px"}}>
                                                            <Button style={{marginRight:"5px", paddingTop: "9px"}} variant="contained" color="primary" onClick={() => this.showVotes()}>
                                                                Show Votes
                                                            </Button>
                                                            <Button style={{paddingTop: "9px"}} color="primary" onClick={() => this.clearVotes()}>
                                                                Clear Votes
                                                            </Button>                                                    
                                                    </div>
                                                </div>

                                                {/*
                                                    <div>
                                                        <TextField fullWidth margin="normal" value={this.state.message} field="text" onInput={this.handleMessage.bind(this, "message")} id="message" label="Message"/>    
                                                    </div>
                                                    <Button margin="normal" variant="contained" onClick={() => this.emitMessage()}>
                                                        Send Message
                                                    </Button>
                                                */}
                                                <div style={{width:"100%", padding:"15px 0"}}>
                                                    <Users></Users>
                                                </div>
                                                { !this.context.observer &&
                                                    <Grid container spacing={12} style={{textAlign:"center", justifyContent:"center", paddingBottom:"35px", marginTop:"auto"}}>
                                                        <div>
                                                            <Typography style={{padding:"40px 0"}} variant="h6">
                                                                Select one card <span className="pointer-hand">☝️</span>    
                                                            </Typography>
                                                            {points.map(element => (
                                                                <button
                                                                    className={`points-button ${clicked === element.id && "selected"}`} 
                                                                    value={element.id}
                                                                    margin="normal"
                                                                    onClick={this.sendPoints.bind(this, element.id)}
                                                                    variant="contained"
                                                                >
                                                                    {element.id}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </Grid>
                                                }
                                            </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <h3 style={{visibility:"hidden"}}><span style={{marginRight:"4px"}}>🎲</span>Sidebar</h3>
                                            <InviteLink room={this.context.roomJoined}></InviteLink>
                                            <Statistics></Statistics>
                                            <Messages connection={socket}></Messages>
                                        </Grid>
                                    </Grid>

                                </Container>

                        </React.Fragment>                    
                        :
                        
                            <Login/>
                    }
            </div>
        )};
}

export default MainComponent;