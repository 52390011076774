import React, { Component } from 'react';
import { Card, CardContent, Typography} from '@material-ui/core';


class Messages extends Component {
    constructor(props){
        super(props);
        this.state = {
            messages: []
        }
        this.props.connection.on('message', message => {
            this.setState(prevState => ({
                messages: [...prevState.messages, message]
            }))
        })
    }
    
    render() {
        return(
            <div>
                <h3><span className="emoji">💬</span>Chat</h3>
                <div>
                    <Card>
                        <CardContent className="chat-wrapper">
                            <Typography>
                                {this.state.messages
                                ?
                                    this.state.messages.map((message, idx) => { 
                                        return <div style={{fontSize:"12px"}} id={idx + 1}>{message.text} at {message.time}</div>
                                    })
                                :
                                    null
                                }
                            </Typography>
                        </CardContent>
                    </Card>
                    
                </div>
            </div>
        )
    }
    
}
export default Messages;