import React, { Component } from 'react';
import { TableContainer, TableCell, TableRow , TableHead, Table, TableBody, Paper } from '@material-ui/core';
import UserContext from '../context/UserContext';

class Messages extends Component {
    
    static contextType = UserContext;
    
    constructor(props){
        super(props);
    }
    
    componentDidMount() {
        const context = this.context;
        /*
        context.setUsers([{
            id:5,
            username:"Facu",
            room: "Berlin",
            points:4
        }])
        */

    }

    render() {
        const { allUsers } = this.context;
        return(
            <div>
                <h3><span className="emoji">🦸🏽‍♀️</span>Users</h3>
                <TableContainer className="users-table" component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell align="center">Voted</TableCell>
                            <TableCell align="center">Points</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {allUsers
                            .filter(user => user.observer == false)
                            .map((row) => (
                                <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.username}
                                </TableCell>
                                <TableCell align="center">{row.voted ? '✅' : '👎'}</TableCell>
                                <TableCell align="center"><div style={{fontSize:"15px"}}>{this.context.showPoints ? row.points: ''}</div></TableCell>
                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            
        )
    }
    
}
export default Messages;