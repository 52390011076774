import React, {useState, useEffect, useContext} from 'react';

import {FormControlLabel, Checkbox, Button, TextField, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import UserContext from './../context/UserContext';
import {socket} from "./../service/socket";

const UsernameDialog = (props) => {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState('');
    const [userError, setUserError] = useState(false);
    const roomJoined = props.roomJoined;
    

    const userContext = useContext(UserContext);
    
    
    const handleClose = () => {
        if(userContext.userName == ''){
            setUserError(true);
            return false;
        }
        socket.emit('joinRoom', {username:userContext.userName, room: roomJoined, observer: userContext.observer});
        setOpen(false);
    };

    const handleUser = (e) => {
        userContext.setUsername(e.target.value); 
    }
    
    const handleObserver = (e) => {
        userContext.setObserver(e.target.checked);
    }

    useEffect(() => {
        if(userContext.userName == ''){
            setOpen(true);
        }
    });
  
    return (
        <div className="main-dialog">
            <Dialog open={open} 
                onClose={handleClose} 
                fullWidth aria-labelledby="form-dialog-title" 
                style={{paddingBottom: "50px"}}
                disableBackdropClick="true">
                <div style={{display:"flex", justifyContent:"center"}}>
                    <h2>
                        <span style={{marginRight:"5px"}}>♠️</span>
                        Sprint Poker
                    </h2>                    
                </div>
                <DialogContent style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <div style={{padding: "0px 15px", textAlign:"center"}}>
                        <h4 style={{margin: "16px 0px 0px"}}>Scrum Poker Tool for Developers</h4>
                        <p>You are about to join to the Scrum Poker tool! Enjoy while you estimate the different requirements 🙂</p>
                    </div>
                    <TextField
                        autoFocus
                        margin="normal"
                        error={userError} 
                        id="name"
                        required
                        label="Username"
                        type="text"
                        fullWidth
                        onInput={handleUser}
                        variant="outlined"
                        inputProps={{ maxLength: 15 }}
                    /> 
                    <FormControlLabel control={<Checkbox onChange={handleObserver} color="primary" name="checkedC" />} label="Log In as Observer" />
                </DialogContent>
                <DialogActions>
                    <Button margin="normal" variant="contained" color="primary" onClick={handleClose}>
                        Join Room
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UsernameDialog;