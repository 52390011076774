import { useContext } from 'react';
import UserContext from './../../context/UserContext';
import { TableContainer, TableCell, TableRow , TableHead, Table, TableBody, Paper } from '@material-ui/core';

const Statistics = () => {
    const context = useContext(UserContext);
    
    const getPoints = () => {
        let totalPoints = 0;
        context.allUsers.map((user) => {
            totalPoints = totalPoints + parseInt(user.points);
        })
        return totalPoints;
    }
    
    const getAverage = () => {
        
        let totalPoints = 0;
        let average = 0;
        let usersVoted = context.allUsers.filter((e) => {
            return e.voted != 0;
        });
        usersVoted.map((user) => {
            totalPoints = totalPoints + parseInt(user.points);
        })
        if(totalPoints != 0){
            average = totalPoints / usersVoted.length;    
        }
        return average.toFixed(2);
    }
    
    return(
        <div>
            <h3><span className="emoji">🔢</span>Statistics</h3>
            
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">Average</TableCell>
                            <TableCell align="right">{context.showPoints ? getAverage() : ''}</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">Total Points</TableCell>
                            <TableCell align="right">{context.showPoints ? getPoints() : ''}</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        
    )
}

export default Statistics;